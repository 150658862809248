<template>
  <section class="box-height" style="margin-bottom: 70px">
    <div
      class="box-title noContractCommentApproval"
      style="border: 1px #e5e5e5 solid"
    >
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane name="frist">
          <span class="tagW" slot="label">待我审批</span>
        </el-tab-pane>
        <el-tab-pane name="second">
          <span class="tagW" slot="label">全部</span>
        </el-tab-pane>
      </el-tabs>

      <div style="padding-bottom: 10px">
        <el-row :gutter="24">
          <el-col :span="24" class="table-top" style="margin-top: 0;display:flex;align-items: center;justify-content: space-between;">
            <el-form class="normalFrom" @submit.native.prevent>
              <div class="clearfix" style="padding: 0 15px">
                <el-row :span="12">
                  <el-col
                    class="searchRow"
                    style="display: flex; align-items: self-start"
                  >
                    <div class="fl" style="width: 600px">
                      <el-form-item label style="margin-bottom: 5px">
                        <el-input
                          class="search"
                          v-model="searchInfo.searchKey"
                          @keyup.enter.native="searchData()"
                          placeholder="请输入项目名称 / 姓名，支持模糊搜索"
                        >
                          <el-button
                            type="primary"
                            slot="append"
                            @click="searchData()"
                            >搜 索</el-button
                          >
                        </el-input>
                      </el-form-item>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-form>
            <el-pagination
              :hide-on-single-page="searchInfo.page.total > 10 ? false : true"
              v-if="searchInfo.page.total > 0 && !listLoading"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="searchInfo.page.current"
              :page-sizes="[10, 20, 50, 100]"
              :pager-count="5"
              :page-size="searchInfo.page.size"
              layout="total,prev, pager, next,sizes"
              :total="searchInfo.page.total"
              style="margin-top: 10px;"
            ></el-pagination>
          </el-col>
          <el-col :span="24">
            <el-table
              :header-cell-style="{
                'background-color': '#f5f7fa',
              }"
              :data="ListData"
              class="tabBorder custor"
              v-loading="listLoading"
              style="margin: 0"
            >
              <el-table-column label="候选人" width="120">
                <template #default="scope">
                  <span
                    class="tabHref"
                    @click="showDrawer(scope.row.resumeId)"
                    >{{ scope.row.resumeName }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column label="项目名称" show-overflow-tooltip>
                <template #default="scope">
                  <router-link
                    class="tabHref"
                    target="_blank"
                    :to="{
                      path: '/project/details',
                      query: { id: scope.row.projectId },
                    }"
                    >{{ scope.row.projectName }}
                  </router-link>
                </template></el-table-column
              >
              <el-table-column prop="type" label="审批类型"></el-table-column>
              <el-table-column
                prop="nowNode"
                label="当前审批节点"
              ></el-table-column>
              <el-table-column
                prop="creatorName"
                label="操作顾问"
              ></el-table-column>
              <el-table-column
                prop="createdTime"
                label="创建时间"
              ></el-table-column>
              <el-table-column label="操作" width="180">
                <template slot-scope="scope"
                  ><span
                    v-if="activeName == 'frist'"
                    class="tabHref"
                    @click="showApporval(scope.row.id)"
                    >审核</span
                  >
                  <span
                    v-else
                    class="tabHref"
                    @click="showApporval(scope.row.id)"
                    >查看</span
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              :hide-on-single-page="searchInfo.page.total > 10 ? false : true"
              v-if="searchInfo.page.total > 0 && !listLoading"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="searchInfo.page.current"
              :page-sizes="[10, 20, 50, 100]"
              :pager-count="5"
              :page-size="searchInfo.page.size"
              layout="total,prev, pager, next,sizes"
              :total="searchInfo.page.total"
            ></el-pagination>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 人选详情 -->
    <el-drawer
      :visible.sync="drawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-kh_xq"></i>
        人才
      </div>
      <div class="drawerCon">
        <resumeDetails
          v-if="drawerVisible"
          :resumeDetailId="resumeId"
          :hover="hoverInfo"
          :repeatResume="repeatResume"
        ></resumeDetails>
      </div>
    </el-drawer>
    <!-- 审核 -->
    <el-drawer
      :visible.sync="approvalDrawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">无合同开票申请审批</div>
      <div class="drawerCon">
        <noComtractApporval
          ref="noComtractApporval"
          v-if="approvalDrawerVisible"
          :id="contractId"
          @close="handleClose"
        ></noComtractApporval>
      </div>
    </el-drawer>
  </section>
</template>
<script>
import {
  noContractFinanceList, //无合同审批分页列表
  noContractFinanceAll, //所有无合同开票审批记录
} from "../../api/api";
import resumeDetails from "../../components/resumeDetail";
import noComtractApporval from "../../components/tools/noComtractApporval";
export default {
  components: { resumeDetails, noComtractApporval },
  data() {
    return {
      searchInfo: {
        page: {
          current: 1,
          size: 50,
          total: 0,
        },
        searchKey: "",
      },
      ListData: [],
      listLoading: false,
      drawerVisible: false,
      resumeId: null,
      hoverInfo: { matchAll: true, majorSearch: false, hover: true },
      repeatResume: { type: "列表", valId: "" },
      approvalDrawerVisible: false,
      contractId: "",
      activeName: "frist",
    };
  },
  created() {
    this.approvalDrawerVisible = this.$route.query.id ? true : false;
    this.contractId = this.$route.query.id ? this.$route.query.id : "";
    this.getData();
  },
  methods: {
    searchData() {
      this.searchInfo.page.current = 1;
      this.getData();
    },
    //获取表格
    getData() {
      this.listLoading = true;
      let req = null;
      if (this.activeName == "frist") {
        req = noContractFinanceList;
      } else {
        req = noContractFinanceAll;
      }
      req(this.searchInfo).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          this.searchInfo.page.total = res.result.total;
        }
      });
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.searchInfo.page.size = val;
      this.searchInfo.page.current = 1;
      this.getData();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.searchInfo.page.current = val;
      this.getData();
    },
    //弹出抽屉
    showDrawer(id) {
      this.drawerVisible = true;
      this.resumeId = id;
    },
    //关闭抽屉
    handleClose(val) {
      this.drawerVisible = false;
      this.approvalDrawerVisible = false;
      if (val == "approve") {
        this.getData();
      }
    },
    //审核
    showApporval(id) {
      this.contractId = id;
      this.approvalDrawerVisible = true;
    },
    //类型切换
    handleClick(tab, e) {
      // console.log(tab)
      this.searchInfo.page = {
        current: 1,
        size: 50,
        total: 0,
      };
      this.ListData = [];
      this.searchInfo.searchKey = "";
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
@import "../Pipeline/index.less";
.searchL {
  width: calc(100% - 190px);
}
:deep(.el-tabs__nav-wrap) {
  padding: 0 15px;
}
:deep(.el-tabs__nav-wrap::after) {
  height: 1px;
}
.search {
  height: 35px;
  line-height: 35px;
  :deep(.el-input__inner) {
    height: 35px !important;
    line-height: 35px !important;
    border-color: #e60012 !important;
  }
  :deep(.el-input-group__append) {
    border-color: #e60012 !important;
    background-color: #e60012;
    height: 33px !important;
    line-height: 33px !important;
    color: #fff;
    font-weight: bold;
  }
  :deep(.el-input-group__append:hover) {
    background-color: #cc0000;
  }
}
.other {
  line-height: 35px;
  font-size: 13px;
  // float: right;
  span {
    margin: 0;
    cursor: pointer;
    color: #606266;
    &:hover {
      color: #e60012;
    }
  }
  .order {
    padding: 0 8px;
    // border-right: 1px solid #e5e5e5;
  }
  .more {
    padding-left: 8px;
    i {
      margin-left: 8px;
    }
  }
}
.tag {
  margin-right: 8px;
}
</style>
